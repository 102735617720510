<template>
  <div id="staff-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-container class="py-0" v-if="floatButtonFlag">
        <v-speed-dial
          v-if="floatButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
          </v-btn>-->
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-if="CreateButtonFlag"
            @click.prevent="enableAddRecordPrompt"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
          </v-btn>-->
        </v-speed-dial>
      </v-container>
      <v-dialog v-model="addRecordPrompt" persistent scrollable>
        <zgb-team-members-modify
          pageDescription="Assign SMA ZGB members"
          :addRecordPrompt="addRecordPrompt"
          :ZoneCode="ZoneCode"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></zgb-team-members-modify>
      </v-dialog>

      <v-dialog v-model="editRecordPrompt" persistent scrollable>
        <zgb-team-members-edit
          pageDescription="Edit SMA ZGB members"
          :editRecordPrompt="editRecordPrompt"
          :recordData="selectedData"
          :ZoneId="ZoneCode"
          @hideEditRecordPrompt="hideEditRecordPrompt"
          v-if="editRecordPrompt"
        ></zgb-team-members-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="getTableRecords"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  small
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  v-if="CreateButtonFlag"
                  @click.prevent="enableAddRecordPrompt"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#66cd00"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Assign Member
                </v-btn>
              </v-col>
            </v-row>
            <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              v-if="ExcelDownloadFlag"
              color="info"
              @click.prevent="
                generateExcel(
                  tableData1,
                  tableOptions1.ExcelFields,
                  tableOptions1.ExcelFileName,
                  'My Worksheet'
                )
              "
              class="mr-4"
              >Download</v-btn
            >
          </v-col>
          <p></p>
        </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="!ResultFlag && !SearchFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag && !SearchFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} members found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-data-iterator
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header></template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="4"
                    >
                      <v-card raised hover ripple elevation="25">
                        <v-card-title>{{ item.MonthTxt }}</v-card-title>
                        <v-card-text>
                          <v-avatar size="200">
                            <img
                              :src="item.ProfilePic"
                              :alt="item.MemberFullname"
                            />
                          </v-avatar>
                          <h3>{{ item.MemberFullname }}</h3>
                          <h3 class="text-primary">
                            {{ item.DesignationName }}
                          </h3>
                          <h4
                            style="color: green"
                            v-if="RoleId == 1 || RoleId == 2 || RoleId == 4"
                          >
                            {{ item.MembershipId }}
                          </h4>
                          <h4 style="color: red">{{ item.LomName }}</h4>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="red"
                            class="font-size-h6 white--text"
                            @click="editData(item, e)"
                            v-if="item.DesignationId != 45 && EditButtonFlag"
                          >
                            <v-icon>mdi-pencil</v-icon>Edit
                          </v-btn>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#ff0000"
                            class="font-size-h6 white--text"
                            @click="deleteAlert(item)"
                            v-if="DeleteButtonFlag"
                          >
                            <v-icon>mdi-delete</v-icon> Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import ZgbTeamMembersModify from "@/view/pages/erp/team-members/sma-zgb/SMAZgbTeamMembersModify.vue";
import ZgbTeamMembersEdit from "@/view/pages/erp/team-members/sma-zgb/SMAZgbTeamMembersEdit.vue";

export default {
  mixins: [common],
  components: {
    ZgbTeamMembersModify,
    ZgbTeamMembersEdit,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      floatButtonFlag: false,

      ExcelDownloadFlag: false,
      ExcelFileName: "",
      tableOptions1: [],
      tableData1: [],

      rows: [],
      tableSelectFlag1: false,
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      CreateButtonFlag: false,
      EditButtonFlag: false,
      DeleteButtonFlag: false,
      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      RoleId: "",
    };
  },
  mounted() {},
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        this.RoleId = RoleId;

        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "sma_zone_governing_board",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      // this.getTableRecords();
      this.getZoneCodeOptions();
    },
    enableAddRecordPrompt() {
      console.log("enableAddRecordPrompt called");
      this.addRecordPrompt = true;
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.getTableRecords();
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.getTableRecords();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        NotAvailable: 0,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/lists";
        var upload = {
          UserInterface: 6,
          OrganizationTypeId: 5,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
          ExceptDesignationId: [1, 5, 7, 6],
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var ResultFlag = false;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.SearchFlag = true;

        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.tableData1 = [];

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableSelectFlag1 = records.TableSelectFlag;
            thisIns.CreateButtonFlag = records.CreateButtonFlag;
            thisIns.EditButtonFlag = records.TableEditFlag;
            thisIns.DeleteButtonFlag = records.TableDeleteFlag;
            var options = records.TableOptions;
            thisIns.tableOptions1 = options;
            if (flag == 1) {
              var TableData = records.TableData;
              var n1 = TableData.length;
              console.log({ n1 });
              thisIns.rows = TableData;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              thisIns.ExcelDownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              ResultFlag = n1 > 0 ? true : false;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
            thisIns.ResultFlag = ResultFlag;
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ResultFlag = true;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Name <b> " + tr.MemberFullname + "</b>";
        htmlTxt += "<br/>Designation <b> " + tr.DesignationName + "</b>";
        htmlTxt += "<br/>Membership Id <b> " + tr.MembershipId + "</b>";
        htmlTxt += "<br/>Lom <b> " + tr.LomName + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      // add todo in store and clear all fields in dialog
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/member-delete";
        var upload = {
          UserInterface: 8,
          OrganizationType: 5,
          Year: this.JciYearCode,
          Zone: tr.ZoneId,
          Lom: tr.LomId,
          Member: tr.MemberId,
          Designation: tr.DesignationId,
          YearwiseOfficesHeld: tr.YearwiseOfficesHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#staff-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>